<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Kulüp Raporları'" class="mr-2 text-white"></label>
        <b-button variant="primary" class="ml-2" to="/clubReportSelect">Geri</b-button>
      </template>
      <InputTile label="Bölge" :custom="true">
        <b-form-select
          v-model="form.region"
          :options="formOptions.cities"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Akarsu Akredite" :custom="true">
        <b-form-select v-model="form.runningWater" :options="licence" required>
        </b-form-select>
      </InputTile>
      <InputTile label="Durgunsu Akredite" :custom="true">
        <b-form-select v-model="form.stillWater" :options="licence" required>
        </b-form-select>
      </InputTile>
      <InputTile label="Deniz Kanosu Akredite" :custom="true">
        <b-form-select v-model="form.seaKayaking" :options="licence" required>
        </b-form-select>
      </InputTile>
      <InputTile label="Dragonbot Akredite" :custom="true">
        <b-form-select v-model="form.dragonBoat" :options="licence" required>
        </b-form-select>
      </InputTile>
      <InputTile label="Rafting Akredite" :custom="true">
        <b-form-select v-model="form.rafting" :options="licence" required>
        </b-form-select>
      </InputTile>

      <download-excel
      class="btn btn-success float-right mt-4 ml-2"
        :fetch="getData"
        :fields="
          reports.club.fields.reduce((obj, e) => {
            obj[e] = e;
            return obj;
          }, {})
        "
        :worksheet="reports.club.title"
        name="filename.xls"
      >
        Excel al
      </download-excel>
      
      <b-btn class="float-right mt-4" variant="primary" @click="downloadPdf"
        >Rapor Al</b-btn
      >
    </b-card>
  </b-container>
</template>

<script>
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "@/store/constants/reports.js";
import InputTile from "../../components/forms/common/InputTile.vue";
import cities from "../../store/constants/cities.js";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    InputTile,
    "download-excel": JsonExcel,
  },
  data() {
    return {
      reports,
      formOptions: {
        cities: cities.map(function (e) {
          return e.value == "" ? { text: "Tümü", value: "" } : e;
        }),
      },
      form: {
        region: "",
        runningWater: "0",
        stillWater: "0",
        seaKayaking: "0",
        dragonBoat: "0",
        rafting: "0",
      },
      licence: [
        { value: "0", text: "Tümü" },
        { value: "1", text: "Akreditasyonu Dolanlar" },
        { value: "2", text: "Akredite Olmayanlar" },
        { value: "3", text: "Akredite Olanlar" },
      ],
    };
  },
  methods: {
    async downloadPdf() {
      reportHelper.generateHTML(
        reports.club.title,
        reports.club.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.club.endpoint,
          this.form
        )
      );
    },
    async getData() {
      const data = await reportHelper.getPdfItemsFromServer(
        reports.club.endpoint,
        this.form
      );
      
      return data.map((e) => {
        const row = {};
        reports.club.fields.forEach((element, index) => {
          row[element] = e[index];
        });

        return row;
      });
    },
  },
};
</script>

<style>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>